import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setUserData } from "../../Redux/Action/userAction";
import { userServices } from "../../services/apiService/userServices";
import {
  campaignInfoLocal,
  userDataLocal,
} from "../../services/localService/localService";
import PrizeItem from "./PrizeItem";

export default function GetPrizePage() {
  let { giftCode } = useParams();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const dataGame = campaignInfoLocal.get();

  let [isLoading, setIsLoading] = useState("TRUE");
  let [isSubmit, setIsSubmit] = useState(false);
  let [seasionGift, setSeasionGift] = useState({});
  let userData = useSelector((state) => {
    return state.userReducer.userData;
  });
  let userPhone = userData.wheel.phone;
  useEffect(() => {
    userServices
      .getSeasionReward(userPhone)
      .then((res) => {
        setIsLoading("FALSE");
        console.log("res: ", res);
        setSeasionGift(res);
        if (res.session.gifts.length === 0) {
          toast.error("Không có quà để nhận!");
          setTimeout(function () {
            navigate("/spinthewheel/" + giftCode);
          }, 7000);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);
  const renderAllGift = () => {
    let listGift = seasionGift?.session?.gifts;
    return listGift.map((gift) => {
      return (
        <PrizeItem
          voucher_img={gift.gift_image}
          key={gift.gift_id}
          gift={gift}
        />
      );
    });
  };
  const handlePostReceiveGift = () => {
    setIsSubmit(true);
    userServices
      .postReceiveGift()
      .then((res) => {
        toast.success("Nhận quà thành công");
        setTimeout(function () {
          userDataLocal.set({});
          dispatch(setUserData({}));
          setIsSubmit(false);
        }, 1000);
      })
      .catch((err) => {
        setIsSubmit(false);
        toast.error(err);
      });
  };
  return (
    <>
      {isLoading == "FALSE" ? (
        <div className="get-prize-container">
          <div className="get-prize">
            <div className="prize-content container">
              <h1>xin chúc mừng</h1>
              <p>
                Sau{" "}
                <span className="gift-open-amount">
                  {seasionGift?.session?.used}
                </span>{" "}
                lượt mở lì xì <br />
                Quý Khách{" "}
                {seasionGift?.session?.gift_no > 0 ? (
                  <span>
                    có {seasionGift?.session?.gift_no} lượt không trúng thưởng
                    và
                  </span>
                ) : (
                  " "
                )}{" "}
                nhận được
              </p>
              <div className="all-gift">{renderAllGift()}</div>
            </div>
          </div>
          <div className="get-prize-btn bold">
            <button
              disabled={isSubmit}
              onClick={() => {
                handlePostReceiveGift();
              }}
              style={{
                backgroundColor: dataGame?.button_color,
                color: dataGame?.button_label_color,
              }}
            >
              Nhận quà
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
