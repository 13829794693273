import React from "react";
import gift_bg from "../../assets/bg_gift.png";
import { NavLink, useParams } from "react-router-dom";
import { campaignInfoLocal } from "../../services/localService/localService";

export default function AnnouncePrize({ winningGift, spinRemain }) {
  let { giftCode } = useParams();
  let { gift_name, gift_value, gift_image } = winningGift;
  const handleContinueSpin = () => {
    window.location.reload();
  };
  const dataGame = campaignInfoLocal.get();

  return (
    <div className="annouce-prize container">
      <div
        style={{
          backgroundImage: `url(${gift_value > 0 ? gift_bg : ""})`,
          width: "90vw",
          height: "220px",
          objectFit: "contain",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          marginTop: "20px",
          position: "relative",
          left: "0px",
        }}
      >
        {gift_value > 0 ? (
          <div className="announce-title">
            <h1>
              xin chúc mừng <br />
              Bạn đã trúng thưởng
            </h1>
            {/* <p>Bạn đã trúng thưởng</p> */}
            <h5>
              <span
                className="prize-name"
                style={{
                  color: "#F9E8B9",
                  fontWeight: 900,
                  fontSize: "18px",
                }}
              >
                {gift_name}
              </span>
            </h5>
          </div>
        ) : (
          <div className="announce-title">
            <h1>{gift_name}</h1>
          </div>
        )}
        <div className="prize">
          <img
            src={gift_image}
            alt=""
            style={{ width: "130px", position: "relative", top: "-30px" }}
          />
        </div>
      </div>
      <div
        className="prize-decription"
        style={{ fontWeight: 900, marginTop: "10px" }}
      >
        {gift_value > 0 && (
          <p>
            {winningGift?.gift_code === "wsp2025_dongvang" ? (
              <p>
                <div style={{ fontSize: "italic", fontWeight: 700 }}>
                  *Lưu ý: Hình ảnh quà tặng chỉ mang tính chất minh họa. Một
                  đồng tiền vàng SBJ tương đương{" "}
                  <span style={{ color: "red" }}>
                    1 phân vàng (hay 0,1 chỉ vàng)
                  </span>
                  . Giá trị sản phẩm có thể thay đổi theo giá thị trường.{" "}
                </div>
                Đối với giải thưởng là thẻ quà tặng điện tử: Quý khách sẽ được
                nhận được link voucher thông qua tin nhắn điện thoại của mình
                trong vòng 5 phút.
                <br /> Đối với giải thưởng là hiện vật: Hotline{" "}
                <span style={{ color: "red" }}>02836222363</span> sẽ liên hệ để
                thu thập thông tin trao giải trong vòng{" "}
                <span style={{ color: "red" }}>7-10</span> ngày tới. Phần thưởng
                sẽ được trao trong vòng{" "}
                <span style={{ color: "red" }}>20-25</span> ngày tính từ lúc lấy
                thông tin trao thưởng thành công.
                <br />
                <div style={{ fontStyle: "italic" }}>
                  *Hình ảnh quà tặng mang tính chất minh họa. Giá trị sản phẩm
                  thay đổi theo giá thị trường
                </div>
              </p>
            ) : (
              <p>
                {" "}
                Đối với giải thưởng là thẻ quà tặng điện tử: Quý khách sẽ được
                nhận được link voucher thông qua tin nhắn điện thoại của mình
                trong vòng 5 phút.
                <br />
                Đối với giải thưởng là hiện vật: Hotline{" "}
                <span style={{ color: "red" }}>02836222363</span> sẽ liên hệ để
                thu thập thông tin trao giải trong vòng{" "}
                <span style={{ color: "red" }}>7-10</span> ngày tới. Phần thưởng
                sẽ được trao trong vòng{" "}
                <span style={{ color: "red" }}>20-25</span> ngày tính từ lúc lấy
                thông tin trao thưởng thành công.
                <br />
                <div style={{ fontStyle: "italic" }}>
                  *Hình ảnh quà tặng mang tính chất minh họa. Giá trị sản phẩm
                  thay đổi theo giá thị trường
                </div>
              </p>
            )}
          </p>
        )}
      </div>
      <div className="button-nav">
        <NavLink
          to={"/get-prize/" + giftCode}
          style={{ textDecoration: "none" }}
          className="get-prize"
        >
          Dừng quay
          <br />
          (nhận quà)
        </NavLink>
        <button
          onClick={() => {
            handleContinueSpin();
          }}
          className="continue"
          style={{
            backgroundColor: dataGame?.button_color,
            color: dataGame?.button_label_color,
          }}
        >
          <span className="bold">Quay tiếp</span>{" "}
          <span className="spin-remain">{spinRemain} lượt</span>
        </button>
      </div>
    </div>
  );
}
