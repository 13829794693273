import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { userServices } from "../../services/apiService/userServices";
import voucher_img from "../../assets/voucher.png";
import spin_img from "../../assets/spinButton.png";

import "./wheel.css";
import { useNavigate, useParams } from "react-router-dom";
import AnnouncePrize from "../AnnouncePrice/AnnouncePrize";
import { campaignInfoLocal } from "../../services/localService/localService";

export default function SpinTheWheel() {
  let navigate = useNavigate();
  const dataGame = campaignInfoLocal.get();
  let { giftCode } = useParams();
  const [giftAmount, setgiftAmount] = useState(8);
  const [spinRemain, setSpinRemain] = useState(0);
  const [segments, setSegments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSpinning, setIsSpinning] = useState(false);
  const [transformBox, settransformBox] = useState("");
  const [newGiftList, setNewGiftList] = useState([]);
  const [winningGift, setWinningGift] = useState({});

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  };

  useEffect(() => {
    userServices
      .getWheelReward()
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        toast.success(response);
        setSpinRemain(response.wheel.remain);
        if (response.wheel.remain === 0) {
          return navigate("/endofspin/" + giftCode);
        }
        const shuffleGiftList = (array) => {
          console.log(array);
          let currentIndex = array.length,
            randomIndex;

          // While there remain elements to shuffle.
          while (currentIndex != 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
              array[randomIndex],
              array[currentIndex],
            ];
          }

          return array;
        };
        const createNewGiftArrayForWheel = () => {
          let newGiftList = [];
          let severGiftList = response.wheel.gifts;

          if (severGiftList.length > 10) {
            severGiftList = severGiftList.slice(0, 10);
          }
          let notBigGiftList = severGiftList.filter((gift) => {
            return gift.is_big == false;
          });
          let bigGiftList = severGiftList.filter((gift) => {
            return gift.is_big == true;
          });
          if (severGiftList.length < 10) {
            let lackLenght = 10 - severGiftList.length;
            while (lackLenght > 0) {
              notBigGiftList = [
                ...notBigGiftList,
                notBigGiftList[getRandomInt(0, notBigGiftList.length)],
              ];
              lackLenght--;
            }
            console.log(notBigGiftList);
          }

          newGiftList = [
            ...shuffleGiftList([...bigGiftList, ...notBigGiftList]),
          ];

          setNewGiftList(newGiftList);

          return newGiftList;
        };
        const getDataGift = () => {
          let list_prizes = createNewGiftArrayForWheel();
          list_prizes = list_prizes.map(function (item, index) {
            return {
              id: index + 1,
              text: item.name,
              img: item.gift_image,
            };
          });
          console.log(list_prizes);
          setSegments(list_prizes);
        };
        getDataGift();
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  const runWheel = () => {
    setIsSpinning(true);
    userServices
      .postSpinGift()
      .then((res) => {
        console.log(res);
        setWinningGift(res.gift);
        let giftWinning = res.gift;

        var array_angle = [
          { id: 1, angle: 0 },
          { id: 2, angle: -36 },
          { id: 3, angle: -72 },
          { id: 4, angle: -108 },
          { id: 5, angle: -144 },
          { id: 6, angle: -252 },
          { id: 7, angle: -288 },
          { id: 8, angle: -324 },
          { id: 9, angle: -216 },
          { id: 10, angle: -180 },
        ];
        if (giftWinning !== null) {
          let index = newGiftList.findIndex((gift) => {
            return gift.gift_id == giftWinning.gift_id;
          });
          let angle = array_angle[index].angle;
          settransformBox("rotate(" + (angle + 360 * 6) + "deg)");

          var element = document.getElementById("mainbox");
          let prize = document.getElementById("prize");
          let wheel = document.getElementById("wheel");
          setTimeout(function () {
            element.classList.remove("animate");
            prize.style.display = "block";
            wheel.style.display = "none";
            setSpinRemain(res.wheel.remain);
            if (res.wheel.remain === 0) {
              navigate("/get-prize/" + giftCode);
            }
            setIsSpinning(false);
          }, 5000);
        }
      })
      .catch((err) => {
        setIsSpinning(false);
        toast.error(err);
      });
  };
  useEffect(() => {
    <AnnouncePrize winningGift={winningGift} />;
  }, [winningGift]);

  return (
    <>
      {isLoading === false ? (
        <div className="">
          <div id="prize" className="hide-prize">
            <AnnouncePrize winningGift={winningGift} spinRemain={spinRemain} />
          </div>
          <div id="wheel" className="">
            <div className="wheel-container-vongquay wheel">
              <>
                <div id="bg" className="bg">
                  <div id="mainbox" className="mainbox">
                    <div
                      id="box"
                      className="box nenvongquay"
                      style={{ transform: `${transformBox}` }}
                    >
                      <div className="box1">
                        <span className="segment span1 color-1">
                          <i id="id_7" className="item7 segments">
                            <h6>{segments[6]?.text}</h6>
                            <img
                              src={segments[6]?.img}
                              alt={segments[6]?.text}
                            />
                          </i>
                        </span>
                        <span className="segment span2 color-1">
                          <i id="id_3" className="item3 segments">
                            <h6>{segments[2]?.text}</h6>
                            <img
                              src={segments[2]?.img}
                              alt={segments[2]?.text}
                            />
                          </i>
                        </span>
                        <span className="segment span3 color-1">
                          <i id="id_5" className="item5 segments">
                            <h6>{segments[4]?.text}</h6>
                            <img
                              src={segments[4]?.img}
                              alt={segments[4]?.text}
                            />
                          </i>
                        </span>
                        <span className="segment span4 color-1">
                          <i id="id_1" className="item1 segments">
                            <h6>{segments[0]?.text}</h6>
                            <img
                              src={segments[0]?.img}
                              alt={segments[0]?.text}
                            />
                          </i>
                        </span>
                        <span className="segment span5 color-1">
                          <i id="id_9" className="item1 segments">
                            <h6>{segments[8]?.text}</h6>
                            <img
                              src={segments[8]?.img}
                              alt={segments[8]?.text}
                            />
                          </i>
                        </span>
                      </div>
                      <div className="box2">
                        <span className="segment span1 color-2">
                          <i id="id_4" className="item4 segments">
                            <h6>{segments[3]?.text}</h6>
                            <img
                              src={segments[3]?.img}
                              alt={segments[3]?.text}
                            />
                          </i>
                        </span>
                        <span className="segment span2 color-2">
                          <i id="id_8" className="item8 segments">
                            <h6>{segments[7]?.text}</h6>
                            <img
                              src={segments[7]?.img}
                              alt={segments[7]?.text}
                            />
                          </i>
                        </span>
                        <span className="segment span3 color-2">
                          <i id="id_2" className="item2 segments">
                            <h6>{segments[1]?.text}</h6>
                            <img
                              src={segments[1]?.img}
                              alt={segments[1]?.text}
                            />
                          </i>
                        </span>
                        <span className="segment span4 color-2">
                          <i id="id_6" className="item6 segments">
                            <h6>{segments[5]?.text}</h6>
                            <img
                              src={segments[5]?.img}
                              alt={segments[5]?.text}
                            />
                          </i>
                        </span>
                        <span className="segment span5 color-2">
                          <i id="id_10" className="item6 segments">
                            <h6>{segments[9]?.text}</h6>
                            <img
                              src={segments[9]?.img}
                              alt={segments[9]?.text}
                            />
                          </i>
                        </span>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        runWheel();
                      }}
                      disabled={isSpinning}
                      className="spin"
                    >
                      <img src={spin_img} alt="" />
                    </button>
                  </div>
                </div>
              </>
            </div>
            <div className="spin-remain-container container">
              <button
                className="spin-remain bold"
                style={{
                  backgroundColor: dataGame?.button_color,
                  color: dataGame?.button_label_color,
                }}
              >
                Bạn có {spinRemain} lượt quay
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
